import * as yup from 'yup';

import { isEmptyValue } from 'features/consults/utils/validators';
import { requiredString } from 'utils/constants';
import { PHONE_NUMBER_REGEX } from 'utils/validators';
import { ServiceTypeEnum } from 'utils/enums';

import { formFields } from './data';

export default yup.object().shape({
  [formFields.firstName]: yup
    .string()
    .required(requiredString)
    .max(150, ({ max }) => `First name should not exceed ${max} characters`),
  [formFields.lastName]: yup
    .string()
    .required(requiredString)
    .max(150, ({ max }) => `Last name should not exceed ${max} characters`),
  [formFields.dateOfBirth]: yup.string().nullable(),
  [formFields.gender]: yup.string().nullable(),
  [formFields.facility]: yup
    .string()
    .required(requiredString),
  [formFields.consultType]: yup
    .string()
    .required(requiredString)
    .test('validSelection', requiredString, (value) => !isEmptyValue(value)),
  [formFields.neuroHospitalConsultType]: yup
    .string()
    .nullable()
    .when(formFields.consultType, {
      is: (value) => value === ServiceTypeEnum.TELE_NEURO,
      then: (schema) => schema.required(requiredString),
    }),
  [formFields.eegReadConsultType]: yup
    .string()
    .nullable()
    .when(formFields.consultType, {
      is: (value) =>
        [ServiceTypeEnum.EEG, ServiceTypeEnum.PEDS_EEG].includes(value),
      then: (schema) => schema.required(requiredString),
    }),
  [formFields.videoType]: yup.string().when(formFields.consultType, {
    is: (value) =>
      [ServiceTypeEnum.EEG, ServiceTypeEnum.PEDS_EEG].includes(value),
    then: (schema) => schema.required(requiredString),
  }),
  [formFields.patientLocation]: yup
    .string()
    .required(requiredString)
    .test('validSelection', requiredString, (value) => !isEmptyValue(value)),
  [formFields.provider]: yup
    .string()
    .max(256, ({ max }) => `Provider name should not exceed ${max} characters`),
  [formFields.phoneNumber]: yup
    .string()
    .matches(PHONE_NUMBER_REGEX, 'Invalid phone number')
    .required(requiredString)
    .typeError(requiredString),
  [formFields.neuroCameraName]: yup
    .string()
    .max(255, ({ max }) => `Camera name should not exceed ${max} characters`),
  [formFields.neuroDirectBeamInRequested]: yup.array().of(yup.string()),
  [formFields.eegTechName]: yup
    .string()
    .max(
      255,
      ({ max }) => `Technician name should not exceed ${max} characters`,
    ),
  [formFields.eegTechCallBackNumber]: yup
    .string()
    .nullable()
    .matches(PHONE_NUMBER_REGEX, 'Invalid phone number')
    .when(formFields.consultType, {
      is: (value) =>
        [ServiceTypeEnum.EEG, ServiceTypeEnum.PEDS_EEG].includes(value),
      then: (schema) =>
        schema.required(requiredString).typeError(requiredString),
    }),
  [formFields.statReadRequested]: yup.array().of(yup.string()),
  [formFields.notes]: yup
    .string()
    .max(20000, ({ max }) => `Notes should not exceed ${max} characters`),
});
